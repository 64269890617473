import React, { useEffect, useState } from 'react';
import { globalStore } from '~/store';
import { getHash } from '~/utils';
import JsonView from 'react18-json-view';
import 'react18-json-view/src/style.css';

interface BatteryData {
  level: number | null;
  charging: boolean | 'Unknown';
  chargingTime: number | null;
  dischargingTime: number | null;
}

const BatteryComponent: React.FC = () => {
  const [batteryData, setBatteryData] = useState<{ hash: string; data: BatteryData }>();
  const [isSupported, setIsSupported] = useState<boolean>(false);

  useEffect(() => {
    const updateBatteryData = async (battery) => {
      const data: BatteryData = {
        level: battery.level ?? null,
        charging: battery.charging ?? 'Unknown',
        chargingTime: battery.chargingTime ?? null,
        dischargingTime: battery.dischargingTime ?? null,
      };

      const hash = await getHash(JSON.stringify(data));
      setBatteryData({ hash, data });
      globalStore.set({ ...globalStore.get(), battery_status: { hash, data } });
    };

    if ('getBattery' in navigator) {
      setIsSupported(true);
      (navigator as any).getBattery().then((battery) => {
        updateBatteryData(battery);

        battery.onchargingchange = () => updateBatteryData(battery);
        battery.onlevelchange = () => updateBatteryData(battery);
        battery.onchargingtimechange = () => updateBatteryData(battery);
        battery.ondischargingtimechange = () => updateBatteryData(battery);
      });
    } else {
      setIsSupported(false);
    }
  }, []);

  return (
    <div>
      <div>
        <h2 className="text-3xl font-medium mb-5 flex items-center gap-2">Battery</h2>
        <div className="overflow-auto border p-2 rounded-lg border-slate-200 h-auto max-h-[500px]">
          {batteryData ? <JsonView src={batteryData} /> : isSupported ? <p>Loading battery data...</p> : <p>Battery API is not supported on this device.</p>}
        </div>
      </div>
    </div>
  );
};

export default BatteryComponent;
